import React, { useState } from "react"
import "./Sidebar.css"
import { NavLink } from "react-router-dom"
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import PendingActionsIcon from "@mui/icons-material/PendingActions"
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded"
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded"
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded"

export default function SideBar(props) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const isAdmin = userInfo.scopes.includes("admin") || false
  const isAdminView = userInfo.scopes.includes("admin_view_only") || false
  const hasFilesAccess = userInfo.scopes.includes("rawfiles_view") || false
  const institutions = JSON.parse(localStorage.getItem("institutions"))
  const [labSelected, setLabSelected] = useState(userInfo.institution_id)

  const { t } = useTranslation()

  function handleLabChanges(e) {
    setLabSelected(e.target.value)
    props.setSelectedInstitution(e.target.value)
  }

  function shouldNavigate(e, type) {
    if (type === "manageUsers" || type === "manageInstitutions") {
      e.preventDefault()
    }
  }

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-lab-container">
        <p className="sidebar-lab-label">
          <Trans t={t} i18nKey="sideBar.connectedMessage">
            Συνδεθήκατε στο εργαστήριο:
          </Trans>
        </p>
        <Select
          value={labSelected}
          displayEmpty
          onChange={(e) => handleLabChanges(e)}
          hidden={!isAdmin}
          sx={{
            width: "100%",
            height: "43px",
            backgroundColor: "#f3f3f3",
          }}
        >
          <MenuItem hidden slotprops={{ textField: { size: "small" } }}>
            <em>
              <Trans t={t} i18nKey="sideBar.selectLab">
                Επιλέξτε εργαστήριο
              </Trans>
            </em>
          </MenuItem>

          {institutions.map((institution) => (
            <MenuItem key={institution.inst_id} value={institution.inst_id}>
              {institution.inst_name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <NavLink
        className={({ isActive }) =>
          isActive ? "sidebar-link active" : "sidebar-link"
        }
        to="/"
      >
        <LeaderboardRoundedIcon />
        <Trans t={t} i18nKey="sideBar.dashboard">
          Κεντρική
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive ? "sidebar-link active" : "sidebar-link"
        }
        to="/institution"
      >
        <ApartmentRoundedIcon />
        <Trans t={t} i18nKey="sideBar.institution">
          Εργαστήριο
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive ? "sidebar-link active" : "sidebar-link"
        }
        to="/bio-data"
      >
        <VaccinesRoundedIcon />
        <Trans t={t} i18nKey="sideBar.bioData">
          Bugs & Drugs
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "sidebar-link active"
            : hasFilesAccess
            ? "sidebar-link"
            : "sidebar-link hidden"
        }
        to="/files/all"
      >
        <FilePresentRoundedIcon />
        <Trans t={t} i18nKey="sideBar.allFiles">
          Όλα τα Αρχεία
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "sidebar-link active"
            : isAdminView || !hasFilesAccess
            ? "sidebar-link hidden"
            : "sidebar-link"
        }
        to="files/upload"
      >
        <CloudUploadRoundedIcon />
        <Trans t={t} i18nKey="sideBar.upload">
          Ανέβασμα Αρχείου
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "sidebar-link active"
            : isAdmin
            ? "sidebar-link"
            : "sidebar-link hidden"
        }
        to="files/pending"
        hidden
      >
        <PendingActionsIcon />
        <Trans t={t} i18nKey="sideBar.pending">
          Εκκρεμή Αρχεία
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "sidebar-link active"
            : isAdmin
            ? "sidebar-link"
            : "sidebar-link hidden"
        }
        to="manage-users"
        onClick={(e) => shouldNavigate(e, "manageUsers")}
        hidden
      >
        <SettingsRoundedIcon />
        <Trans t={t} i18nKey="sideBar.manageUsers">
          Χρήστες
        </Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "sidebar-link active"
            : isAdmin
            ? "sidebar-link"
            : "sidebar-link hidden"
        }
        to="manage-users"
        onClick={(e) => shouldNavigate(e, "manageInstitutions")}
        hidden
      >
        <SettingsRoundedIcon />
        <Trans t={t} i18nKey="sideBar.manageInstitutions">
          Εργαστήρια
        </Trans>
      </NavLink>
    </div>
  )
}

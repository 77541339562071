import React, { useEffect, useState } from "react"
import "./UploadedFiles.css"
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded"
import { useLocation } from "react-router-dom"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Pagination from "@mui/material/Pagination"
import { Oval } from "react-loader-spinner"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded"
import { Fade, IconButton, Tooltip, Zoom } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { apiCaller } from "../../Api/ApiCaller"
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded"

export default function UploadedFiles() {
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const [fetchingErrors, setFetchingErrors] = useState("")
  const location = useLocation()
  const itemsPerPage = 9
  const [showMessage, setShowMessage] = useState(false)
  const [page, setPage] = useState(1)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [downloading, setDownloading] = useState(false)
  const [listFilesStatus, setListFilesStatus] = useState([])
  const [allUsers, setAllUsers] = useState([])

  const institutions = JSON.parse(localStorage.getItem("institutions"))
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    setFetchingErrors("")
    Promise.all([
      apiCaller(
        `${process.env.REACT_APP_IP}/rawfiles/`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/fake-users`,
        "GET",
        "application/json",
        null,
        true
      ),
    ])
      .then((data) => {
        setUploadedFiles(data[0])
        setAllUsers(data[1])
        setLoading(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
          setLoading(false)
        }
      })

    if (
      location.state?.from === "upload" &&
      localStorage.getItem("fileUploaded")
    ) {
      setShowMessage(true)
    }
  }, [])

  useEffect(() => {
    setListFilesStatus(uploadedFiles.map(() => false))
  }, [uploadedFiles])

  function messageOnClose(event, reason) {
    if (reason === "clickaway") {
      return
    }
    setShowMessage(false)
    localStorage.removeItem("fileUploaded")
  }

  useEffect(() => {
    setStartIndex(itemsPerPage * (page - 1))
    setEndIndex(itemsPerPage * page)
  }, [page])

  function changePage(event, value) {
    setPage(parseInt(value))
  }

  function handleDownloadFile(index, fileID, fileName) {
    setDownloading(true)
    setListFilesStatus((fileItem) => {
      const newValues = [...fileItem]
      newValues[index] = true
      return newValues
    })
    apiCaller(
      `${process.env.REACT_APP_IP}/rawfiles/${fileID}/file`,
      "GET",
      null,
      null,
      false,
      "downloadFile",
      fileName
    )
      .then(() => {
        setDownloading(false)
        setListFilesStatus((fileItem) => {
          const newValues = [...fileItem]
          newValues[index] = false
          return newValues
        })
      })
      .catch((error) => {
        setListFilesStatus((fileItem) => {
          const newValues = [...fileItem]
          newValues[index] = false
          return newValues
        })
        setDownloading(false)
        setFetchingErrors(error)
      })
  }

  if (fetchingErrors !== "") {
    return (
      <div style={{ height: "550px" }} className="uploaded-files-wrapper">
        <h2 className="uploaded-files-title">{t("uploadedFiles.title")}</h2>
        <hr />
        <Alert sx={{ margin: "20px" }} severity="error" variant="filled">
          {fetchingErrors.message}
        </Alert>
      </div>
    )
  }

  return (
    <div className="uploaded-files-wrapper">
      <h2 className="uploaded-files-title">
        <Trans t={t} i18nKey="uploadedFiles.title">
          Όλα τα ανεβασμένα αρχεία
        </Trans>
      </h2>
      <hr />

      {!loading ? (
        <div className="uploaded-files-container">
          <table>
            <thead>
              <tr>
                <th style={{ width: "8%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.id">
                    ID
                  </Trans>
                </th>
                <th style={{ width: "15%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.lab">
                    Εργαστήριο
                  </Trans>
                </th>
                <th style={{ width: "14%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.date">
                    Ημερομηνία
                  </Trans>
                </th>
                <th>
                  <Trans t={t} i18nKey="uploadedFiles.table.user">
                    Χρήστης
                  </Trans>
                </th>
                <th style={{ width: "8%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.status">
                    Κατάσταση
                  </Trans>
                </th>
                <th style={{ width: "10%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.actions">
                    Ενέργειες
                  </Trans>
                </th>
                <th style={{ width: "35%" }}>
                  <Trans t={t} i18nKey="uploadedFiles.table.file">
                    Αρχείο
                  </Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles
                .sort((a, b) => b.rf_id - a.rf_id)
                .slice(startIndex, endIndex)
                .map((file, index) => {
                  const date = new Date(file.rf_date_uploaded)
                  const formattedDate = `${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()} - ${date.getHours()}:${date
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}:${date
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`
                  return (
                    <tr key={file.rf_id}>
                      <td>{file.rf_id}</td>
                      <td>
                        {
                          institutions.find(
                            (i) => i.inst_id === file.rf_inst_id
                          ).inst_name
                        }
                      </td>
                      <td>{formattedDate}</td>
                      <td>
                        {
                          allUsers.find((u) => u.user_id === file.rf_user_id)
                            .username
                        }
                      </td>
                      <td>
                        {file.rf_status === 1 ? (
                          <Tooltip
                            title={
                              <Trans
                                t={t}
                                i18nKey="uploadedFiles.statusPendingMessage"
                              >
                                Εκκρεμή έλεγχος απο τον Διαχειριστή
                              </Trans>
                            }
                            placement="bottom"
                            arrow
                            TransitionComponent={Fade}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -10],
                                  },
                                },
                              ],
                            }}
                          >
                            <IconButton>
                              <QueryBuilderRoundedIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              <Trans
                                t={t}
                                i18nKey="uploadedFiles.statusPendingMessage"
                              >
                                Ελέγχθηκε από τον Διαχειριστή
                              </Trans>
                            }
                            placement="bottom"
                            arrow
                            TransitionComponent={Fade}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -10],
                                  },
                                },
                              ],
                            }}
                          >
                            <IconButton>
                              <CheckCircleRoundedIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </td>
                      <td>
                        <HighlightOffRoundedIcon className="pending-files-icon delete" />
                      </td>
                      <td
                        onClick={() =>
                          handleDownloadFile(
                            index,
                            file.rf_id,
                            file.rf_filename
                          )
                        }
                      >
                        {downloading && listFilesStatus[index] ? (
                          <div style={{ marginRight: "2px" }}>
                            <Oval
                              visible={true}
                              height="22"
                              width="22"
                              color="black"
                              secondaryColor="grey"
                              strokeWidth={4}
                              strokeWidthSecondary={4}
                            />
                          </div>
                        ) : (
                          <GetAppRoundedIcon className="uploaded-files-icon download" />
                        )}{" "}
                        {file.rf_filename}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>

          <div className="uploaded-files-pagination">
            <Pagination
              page={page}
              onChange={changePage}
              count={Math.ceil(uploadedFiles.length / itemsPerPage)}
              shape="rounded"
              variant="outlined"
              color="primary"
              showFirstButton
              showLastButton
              boundaryCount={2}
            />
          </div>
        </div>
      ) : (
        <div className="uploaded-files-container loader">
          <Oval
            visible={true}
            height="50"
            width="50"
            color="black"
            secondaryColor="grey"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      )}

      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={messageOnClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={messageOnClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          <Trans t={t} i18nKey="uploadedFiles.statusUploadedMessage">
            Το αρχείο ανέβηκε με επιτυχία.
          </Trans>
        </Alert>
      </Snackbar>
    </div>
  )
}

import React, { useEffect, useState, useContext } from "react"
import { Trans } from "react-i18next"
import "./Institution.css"
import { useTranslation } from "react-i18next"
import { apiCaller } from "../../Api/ApiCaller"
import { Oval } from "react-loader-spinner"
import Alert from "@mui/material/Alert"
import { LayoutContext } from "../Layout/Layout"

export default function Institution() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [fetchingErrors, setFetchingErrors] = useState("")
  const [institutionData, setInstitutionData] = useState({})
  const [institutionName, setInstitutionName] = useState("")

  const { selectedInstitution } = useContext(LayoutContext)

  useEffect(() => {
    setLoading(true)
    setFetchingErrors("")
    apiCaller(
      `${process.env.REACT_APP_IP}/institutions/${selectedInstitution}`,
      "GET",
      "application/json",
      null,
      true
    )
      .then((jsonResponse) => {
        setInstitutionName(jsonResponse.inst_name)
        setInstitutionData(jsonResponse)
        setLoading(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
          setLoading(false)
        }
      })
  }, [selectedInstitution])

  if (loading) {
    return (
      <div className="institution-wrapper">
        <div className="institution-boxes-container">
          <h2 className="upload-file-title">
            <Trans t={t} i18nKey="institution.titleNoData">
              Πληροφορίες εργαστηρίου
            </Trans>
          </h2>
          <hr />
          <div className="institution-tables-rows loading">
            <Oval
              visible={true}
              height="50"
              width="50"
              color="black"
              secondaryColor="grey"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
        </div>
      </div>
    )
  }

  if (fetchingErrors !== "") {
    return (
      <div className="institution-wrapper">
        <div className="institution-boxes-container ">
          <h2 className="upload-file-title">
            <Trans t={t} i18nKey="institution.titleNoData">
              Πληροφορίες εργαστηρίου
            </Trans>
          </h2>
          <hr />
          <div style={{ width: "100%", height: "550px", padding: "10px" }}>
            <Alert severity="error" variant="filled">
              {fetchingErrors.message}
            </Alert>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="institution-wrapper">
      <div className="institution-boxes-container">
        <h2 className="upload-file-title">
          <Trans t={t} i18nKey="institution.title">
            Πληροφορίες εργαστηρίου - {{ institutionName }}
          </Trans>
        </h2>
        <hr />
        <div className="institution-tables-rows">
          <div className="institution-table-info">
            <table>
              <tbody>
                <tr>
                  <td>{institutionData.inst_earss_code || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.earssCode">
                      Κωδικός EARSS
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_latitude || "-"}</td>
                  <td>Latitude</td>
                </tr>
                <tr>
                  <td>{institutionData.inst_longitude || "-"}</td>
                  <td>Longitude</td>
                </tr>
                <tr>
                  <td>{institutionData.inst_comments || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.comments">
                      Σχόλια
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_whonet_code || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.whoNetCode">
                      Κωδικός WhoNET
                    </Trans>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="institution-table-info">
            <table>
              <tbody>
                <tr>
                  <td>
                    {institutionData.inst_address !== null ? (
                      <a
                        style={{ color: "#42777a", textDecoration: "none" }}
                        href={`https://maps.google.com/?q=${institutionData.inst_latitude},${institutionData.inst_longitude}`}
                        target="_blank"
                      >
                        {institutionData.inst_address}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.address">
                      Διεύθυνση
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_city_el || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.city">
                      Πόλη
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_postal_code || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.postalCode">
                      Ταχυδρομικός Κώδικας
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_hosp_el || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.hospital">
                      Νοσοκομείο
                    </Trans>
                  </td>
                </tr>
                <tr>
                  <td>{institutionData.inst_dpt_el || "-"}</td>
                  <td>
                    <Trans t={t} i18nKey="institution.table.department">
                      Τμήμα
                    </Trans>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import "../Login.css"
import { Oval } from "react-loader-spinner"
import Alert from "@mui/material/Alert"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import EmailIcon from "@mui/icons-material/Email"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import CoronavirusRoundedIcon from "@mui/icons-material/CoronavirusRounded"
import { changeLanguage } from "i18next"
import { useTranslation } from "react-i18next"
import LanguageIcon from "@mui/icons-material/Language"
import { Trans } from "react-i18next"

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [inputsError, setInputsError] = useState(false)
  const [resetStep, setResetStep] = useState(1)
  const [showPassword, setShowPassword] = useState(false)
  const [userPassword, setUserPassword] = useState("")
  const [userConfirmPassword, setUserConfirmPassword] = useState("")
  const [userEmail, setUserEmail] = useState("")

  // TODO: add error message if passwords don't match

  const { t } = useTranslation()

  function handleMouseClickPasswordButton() {
    setShowPassword(!showPassword)
  }

  function handleNextStep() {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setResetStep(2)
    }, 2000)
  }

  function onSubmit(event) {
    event.preventDefault()
  }

  function handleInputChanges(e, type) {
    if (type === "password") {
      setUserPassword(e.target.value)
    } else if (type === "confirmPassword") {
      setUserConfirmPassword(e.target.value)
    } else if (type === "email") {
      setUserEmail(e.target.value)
    }

    if (type === "password" || type === "confirmPassword") {
      if (userPassword !== userConfirmPassword) {
        setInputsError(true)
      } else {
        setInputsError(false)
      }
    }
  }

  function handleChangeLanguage() {
    const newLanguage = localStorage.getItem("locale") === "en" ? "gr" : "en"
    localStorage.setItem("locale", newLanguage)
    changeLanguage(newLanguage)
  }

  return (
    <div className="login-wrapper">
      <div className="login-language-selector">
        <p
          onClick={handleChangeLanguage}
          className="login-language-selector-text"
        >
          <LanguageIcon fontSize="small" />
          <Trans t={t} i18nKey="forgotPassword.language">
            English
          </Trans>
        </p>
      </div>
      <form className="login-container" onSubmit={onSubmit} to="/">
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/fluency/96/virus.png"
          alt="virus"
        />
        <h3 className="login-logo">WhoNET Platform</h3>
        <div style={{ marginBottom: "-10px" }}>
          <h3>
            <Trans t={t} i18nKey="forgotPassword.title">
              Αλλαγή κωδικού πρόσβασης
            </Trans>
          </h3>
        </div>
        {resetStep === 1 ? (
          <div className="login-title-errors resetPass">
            {loading ? (
              <Oval
                visible={true}
                height="60"
                width="60"
                color="black"
                secondaryColor="white"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            ) : (
              <>
                {inputsError && (
                  <Alert sx={{ width: "100%" }} severity="error">
                    <Trans t={t} i18nKey="forgotPassword.inputsError">
                      Παρακαλώ συμπληρώστε όλα τα παρακάτω πεδία
                    </Trans>
                  </Alert>
                )}

                <div className="login-inputs-container">
                  <TextField
                    error={false}
                    className="login-input"
                    label="Email"
                    placeholder="Email"
                    type="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <button tabIndex="-1" className="login-input-button">
                            <EmailIcon />
                          </button>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => handleInputChanges(e, "email")}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="login-inputs-container">
            <TextField
              error={false}
              label={t("forgotPassword.passwordPlaceholder")}
              placeholder={t("forgotPassword.passwordPlaceholder")}
              className="login-input-field"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <button
                      type="button"
                      tabIndex="-1"
                      className="login-input-button password"
                      onClick={handleMouseClickPasswordButton}
                      edge="start"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </button>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleInputChanges(e, "password")}
            />

            <TextField
              error={false}
              label={t("forgotPassword.confirmPasswordPlaceholder")}
              placeholder={t("forgotPassword.confirmPasswordPlaceholder")}
              className="login-input-field"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <button
                      type="button"
                      tabIndex="-1"
                      className="login-input-button password"
                      onClick={handleMouseClickPasswordButton}
                      edge="start"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </button>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleInputChanges(e, "confirm-password")}
            />
          </div>
        )}
        <button
          onClick={handleNextStep}
          className="login-submit resetPass"
          type="button"
          disabled={
            resetStep === 2
              ? inputsError === false
                ? true
                : false
              : userEmail === ""
              ? true
              : loading
              ? true
              : false
          }
        >
          {resetStep === 1 ? (
            <Trans t={t} i18nKey="forgotPassword.nextStep">
              Επόμενο
            </Trans>
          ) : (
            <Trans t={t} i18nKey="forgotPassword.submit">
              Ενημέρωση Κωδικού
            </Trans>
          )}
        </button>
      </form>
    </div>
  )
}

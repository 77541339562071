import React from "react"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import UploadFileStepTwo from "./UploadFileStepTwo"
import UploadFileStepThree from "./UploadFileStepThree"
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"

export default function UploadFileAdmin(props) {
  const { t } = useTranslation()

  function handleNextStepDisabled() {
    if (props.activeStep === 0) {
      if (props.file === null) {
        return true
      } else {
        return false
      }
    } else if (props.activeStep === 1 && props.checkFinished) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <Stepper className="upload-file-stepper" activeStep={props.activeStep}>
        <Step completed={props.completedSteps[0]}>
          <StepLabel>
            <p
              className={
                props.activeStep === 0
                  ? "upload-file-step-label active"
                  : "upload-file-step-label "
              }
            >
              <Trans t={t} i18nKey="uploadFile.uploadFileAdmin.step_one_title">
                Ανέβασμα Aρχείου
              </Trans>
            </p>
          </StepLabel>
        </Step>

        <Step completed={props.completedSteps[1]}>
          <StepLabel>
            <p
              className={
                props.activeStep === 1
                  ? "upload-file-step-label active"
                  : "upload-file-step-label"
              }
            >
              <Trans t={t} i18nKey="uploadFile.uploadFileAdmin.step_two_title">
                Έλεγχος & Αποστολή
              </Trans>
            </p>
          </StepLabel>
        </Step>
      </Stepper>

      <div className="upload-file-main-details">
        {props.activeStep === 1 && (
          <UploadFileStepTwo
            isAdmin={props.isAdmin}
            setCheckFinished={props.setCheckFinished}
            handleNext={props.handleNext}
          />
        )}

        {props.activeStep === 0 && (
          <UploadFileStepThree
            file={props.file}
            setFile={props.setFile}
            loadingUpload={props.loadingUpload}
            uploadError={props.uploadError}
          />
        )}
      </div>

      <div className="upload-file-buttons-container">
        <button
          onClick={props.handleReset}
          className="upload-file-form-buttons clear"
          disabled={
            props.activeStep === 1 && props.checkFinished === false
              ? true
              : false
          }
        >
          <RestartAltOutlinedIcon />
          <Trans t={t} i18nKey="uploadFile.resetBtn">
            Επαναφορά
          </Trans>
        </button>

        <button
          onClick={props.handleNext}
          className="upload-file-form-buttons"
          disabled={handleNextStepDisabled()}
        >
          {props.activeStep === 1 ? (
            <Trans t={t} i18nKey="uploadFile.uploadBtn">
              Αποστολή Αρχείου
            </Trans>
          ) : (
            <Trans t={t} i18nKey="uploadFile.nextStepBtn">
              Επόμενο Βήμα
            </Trans>
          )}
          {props.activeStep === 1 ? (
            <CloudUploadRoundedIcon sx={{ ml: 1 }} />
          ) : (
            <ArrowCircleRightOutlinedIcon />
          )}
        </button>
      </div>
    </>
  )
}

import React, { useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import "./UploadFileSteps.css"
import { Oval } from "react-loader-spinner"
import { Alert } from "@mui/material"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

export default function UploadFileStepThree(props) {
  const [fileName, setFileName] = useState("")
  const { t } = useTranslation()

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
  } = useDropzone({
    multiple: false,
    accept: {
      "text/csv": [".csv"],
      "text/tab-separated-values": [".tsv"],
      "text/plain": [".txt"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].name)
        props.setFile(acceptedFiles[0])
      }
    },
  })

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#ADADAD",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 300ms ease-in-out",
    height: "350px",
    marginTop: "40px",
  }

  const focusedStyle = {
    borderColor: "#76ABAE",
  }

  const acceptStyle = {
    borderColor: "#76ABAE",
  }

  useEffect(() => {
    if (props.file === null) {
      acceptedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
    }
  }, [props.file])

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isFocused, isDragAccept]
  )

  if (props.loadingUpload) {
    return (
      <div className="upload-file-loader-errors">
        <Oval
          visible={true}
          height="40"
          width="40"
          color="black"
          secondaryColor="grey"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
        <p style={{ fontSize: "1.1rem", fontWeight: "600" }}>
          <Trans
            t={t}
            i18nKey="uploadFile.uploadFileStepThree.fileIsUploadingMessage"
          >
            Παρακαλώ περιμενετε, το αρχείο ανεβαίνει.
          </Trans>
        </p>
      </div>
    )
  }

  if (props.uploadError !== "") {
    return (
      <div className="upload-file-loader-errors">
        <Alert
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
          }}
          severity="error"
        >
          {props.uploadError.message}
        </Alert>
      </div>
    )
  }

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          <Trans
            t={t}
            i18nKey="uploadFile.uploadFileStepThree.fileSelectorMessage"
          >
            Σύρετε και αφήστε κάποιο αρχείο εδώ, ή κάντε κλικ για να επιλέξετε
            το αρχείο.
          </Trans>
        </p>
        {props.file && (
          <p style={{ color: "#d15965", fontWeight: "500" }}>
            <Trans
              t={t}
              i18nKey="uploadFile.uploadFileStepThree.chosenFileTitle"
            >
              Επιλεγμένο αρχείο: {{ fileName }}
            </Trans>
          </p>
        )}
      </div>
    </div>
  )
}

import { useState } from "react"
import "./Login.css"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { Link, Navigate } from "react-router-dom"
import AccountCircle from "@mui/icons-material/AccountCircle"
import Alert from "@mui/material/Alert"
import { Oval } from "react-loader-spinner"
import { changeLanguage } from "i18next"
import { useTranslation } from "react-i18next"
import LanguageIcon from "@mui/icons-material/Language"
import { Trans } from "react-i18next"
import { apiCaller } from "../../Api/ApiCaller"

export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userToken") ? true : false
  )
  const [username, setUsername] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [inputsError, setInputsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetchingErrors, setFetchingErrors] = useState("")

  const { t } = useTranslation()

  function onSubmit(event) {
    event.preventDefault()
    setFetchingErrors("")

    if (username === "" || userPassword === "") {
      setInputsError(true)
    } else {
      setInputsError(false)
      setLoading(true)
      const data_to_post = new URLSearchParams({
        username: username,
        password: userPassword,
      })
      apiCaller(
        `${process.env.REACT_APP_IP}/token`,
        "POST",
        "application/x-www-form-urlencoded",
        data_to_post,
        true,
        "login"
      )
        .then(async () => {
          return Promise.all([
            apiCaller(
              `${process.env.REACT_APP_IP}/users/me/`,
              "GET",
              "application/json",
              null,
              true,
              "me"
            ),
            apiCaller(
              `${process.env.REACT_APP_IP}/institutions`,
              "GET",
              "application/json",
              null,
              true,
              "institutions"
            ),
          ]).then(() => {
            setCurrentUser(true)
            setLoading(false)
          })
        })
        .catch((error) => {
          setFetchingErrors(error)
          localStorage.clear()
          setLoading(false)
        })
    }
  }

  function handleMouseClickPasswordButton() {
    setShowPassword(!showPassword)
  }

  function handleChangeLanguage() {
    const newLanguage = localStorage.getItem("locale") === "en" ? "gr" : "en"
    localStorage.setItem("locale", newLanguage)
    changeLanguage(newLanguage)
  }

  function handleInputChanges(e, type) {
    if (type === "username") {
      setUsername(e.target.value)
    } else if (type === "password") {
      setUserPassword(e.target.value)
    }
  }

  if (currentUser) {
    return <Navigate to="/" replace />
  }

  return (
    <div className="login-wrapper">
      <div className="login-language-selector">
        <p
          onClick={handleChangeLanguage}
          className="login-language-selector-text"
        >
          <LanguageIcon fontSize="small" />
          <Trans t={t} i18nKey="login.language">
            English
          </Trans>
        </p>
      </div>
      {loading && (
        <div className="login-loader-container">
          <Oval
            visible={true}
            height="60"
            width="60"
            color="black"
            secondaryColor="white"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
          <p style={{ fontSize: "1.5rem", fontWeight: "600" }}>
            <Trans t={t} i18nKey="login.loadingMessage">
              Γίνεται συνδεση
            </Trans>
          </p>
        </div>
      )}
      <form className="login-container" onSubmit={onSubmit} to="/">
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/fluency/96/virus.png"
          alt="virus"
        />
        <h3 className="login-logo">WhoNET Platform</h3>
        <div className="login-title-errors">
          {inputsError && (
            <Alert sx={{ width: "100%" }} severity="error">
              <Trans t={t} i18nKey="login.inputsError">
                Παρακαλώ συμπληρώστε όλα τα παρακάτω πεδία
              </Trans>
            </Alert>
          )}

          {fetchingErrors !== "" && (
            <Alert style={{ width: "100%" }} severity="error">
              {fetchingErrors.message}
            </Alert>
          )}
        </div>
        <div className="login-inputs-container">
          <TextField
            error={false}
            className="login-input"
            label="Username"
            placeholder="Username"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <button tabIndex="-1" className="login-input-button">
                    <AccountCircle />
                  </button>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleInputChanges(e, "username")}
          />

          <TextField
            error={false}
            label="Password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <button
                    type="button"
                    tabIndex="-1"
                    className="login-input-button password"
                    onClick={handleMouseClickPasswordButton}
                    edge="start"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </button>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleInputChanges(e, "password")}
          />
        </div>
        <p>
          <Link style={{ color: "black" }} to="/reset-password">
            <Trans t={t} i18nKey="login.forgotPassword">
              Ξεχάσατε τον κωδικό σας;
            </Trans>
          </Link>
        </p>
        <button className="login-submit" type="submit">
          <Trans t={t} i18nKey="login.submit">
            Σύνδεση
          </Trans>
        </button>
      </form>
    </div>
  )
}

export default async function uploadRawFile(file) {
  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_IP}/raw-data-upload/`,
      {
        method: "POST",

        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      }
    )

    if (!response.ok) {
      const jsonResponse = await response.json()
      const status = response.status
      switch (status) {
        case 401:
          localStorage.clear()
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          window.location.reload()
          throw new Error("Αποτυχία σύνδεσης. Παρακαλώ συνδεθείτε ξανά.")
        case 400:
          throw new Error(jsonResponse.detail)
        default:
          throw new Error("Άγνωστο σφάλμα. Δοκιμάστε ξανά.")
      }
    }
  } catch (error) {
    console.error("[UPLOAD-FILE]: Fetching error:", error)
    if (!error.message) {
      throw new Error("Αποτυχία σύνδεσης. Δοκιμάστε ξανά.")
    } else {
      throw new Error(error.message)
    }
  }
}

import React, { useEffect, useState } from "react"
import TopBarPublic from "../Headers/TopBarPublic"
import { apiCaller } from "../../Api/ApiCaller"
import "./PublicDashboard.css"
import Skeleton from "@mui/material/Skeleton"
import Plot from "react-plotly.js"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { ThreeDots, RotatingLines } from "react-loader-spinner"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionActions from "@mui/material/AccordionActions"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import greecePlaceholder from "./greece_placeholder.json"
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded"
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded"

export default function PublicDashboard() {
  const [loading, setLoading] = useState(true)
  const [fetchingErrors, setFetchingErrors] = useState("") //TODO: handle errors
  const [organismsData, setOrganismsData] = useState([])
  const [antibioticsData, setAntibioticsData] = useState([])
  const [antibioticClassesData, setAntibioticClassesData] = useState([])
  const [graphData, setGraphData] = useState([
    {
      type: "scatter",
      x: [1, 2, 3, 4],
      y: [10, 15, 13, 17],
      mode: "lines",
      name: "",
      line: {
        color: "rgb(173, 173, 173)",
        width: 3,
      },
    },
    {
      type: "scatter",
      x: [1, 2, 3, 4],
      y: [12, 9, 15, 12],
      mode: "lines",
      name: "",
      line: {
        color: "#B8B8B8",
        width: 1,
      },
    },
  ])
  const [graphLayout, setGraphLayout] = useState({})
  const { t } = useTranslation()
  const [filterType, setFilterType] = useState(1)
  const [selectedOrganism, setSelectedOrganism] = useState({ id: "", name: "" })
  const [selectedAntibioticClass, setSelectedAntibioticClass] = useState({
    id: "",
    name: "",
  })
  const [selectedAntibiotic, setSelectedAntibiotic] = useState({
    id: "",
    name: "",
  })
  const [filteredAntibioticsList, setFilteredAntibioticsList] = useState([])
  const [isGettingAntibioticsList, setIsGettingAntibioticsList] =
    useState(false)
  const [isGettingGraph, setIsGettingGraph] = useState(false)
  const [isGraphReady, setIsGraphReady] = useState(false)
  const [expandedAccordion, setExpandedAccordion] = useState(true)
  const [mapData, setMapData] = useState(greecePlaceholder.data)
  const [mapLayout, setMapLayout] = useState(greecePlaceholder.layout)
  const [frameData, setFrameData] = useState(greecePlaceholder.frames)

  useEffect(() => {
    setLoading(true)
    setFetchingErrors("")
    Promise.all([
      apiCaller(
        `${process.env.REACT_APP_IP}/organisms`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotic-classes`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotics`,
        "GET",
        "application/json",
        null,
        true
      ),
    ])
      .then((jsonResponse) => {
        setOrganismsData(jsonResponse[0])
        setAntibioticClassesData(jsonResponse[1])
        setAntibioticsData(jsonResponse[2])

        setLoading(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
          setLoading(false)
        }
      })
  }, [])

  function handleFilterSelectionOrganism(id, name) {
    setSelectedOrganism({ id: id, name: name })
    setFetchingErrors("")
    setIsGettingAntibioticsList(true)

    let url = ""
    if (filterType === 1) {
      url = `${process.env.REACT_APP_IP}/public-classes-from-organism/${id}`
    } else {
      url = `${process.env.REACT_APP_IP}/public-antibiotics-from-organism/${id}`
    }

    apiCaller(url, "GET", "application/json", null, true, "antibioticsList")
      .then((jsonResponse) => {
        setIsGettingAntibioticsList(false)
        setFilteredAntibioticsList(jsonResponse)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
        }
        setIsGettingAntibioticsList(false)
      })
  }

  function handleFilterSelectionAntibiotic(id, name) {
    if (filterType === 1) {
      setSelectedAntibioticClass({ id: id, name: name })
      setSelectedAntibiotic({ id: "", name: "" })
    } else {
      setSelectedAntibiotic({ id: id, name: name })
      setSelectedAntibioticClass({ id: "", name: "" })
    }
  }

  function showGraph() {
    setExpandedAccordion(false)
    setIsGettingGraph(true)
    setIsGraphReady(false)

    let url1 = ""
    let url2 = ""
    if (filterType === 1) {
      url1 = `${process.env.REACT_APP_IP}/public-graph2/regions/class/${selectedOrganism.id}/${selectedAntibioticClass.id}`
      url2 = `${process.env.REACT_APP_IP}/public-map1/regions/class/${selectedOrganism.id}/${selectedAntibioticClass.id}`
    } else {
      url1 = `${process.env.REACT_APP_IP}/public-graph2/regions/antibiotic/${selectedOrganism.id}/${selectedAntibiotic.id}`
      url2 = `${process.env.REACT_APP_IP}/public-map1/regions/antibiotic/${selectedOrganism.id}/${selectedAntibiotic.id}`
    }

    Promise.all([
      apiCaller(url1, "GET", "application/json", null, true),
      apiCaller(url2, "GET", "application/json", null, true),
    ])
      .then((data) => {
        setIsGraphReady(true)

        // GRAPH 1 DATA
        setGraphData(JSON.parse(data[0]).data)
        setGraphLayout(JSON.parse(data[0]).layout)

        // MAP DATA
        // console.log(JSON.parse(data[1]))
        setMapData(JSON.parse(data[1]).frames[3].data)
        setFrameData(JSON.parse(data[1]).frames)

        const updatedSliders = JSON.parse(data[1]).layout.sliders.map(
          (item) => ({
            ...item,
            active: 3,
            steps: item.steps.map((step) => ({
              ...step,
              args: step.args.map((arg) => ({
                ...arg,
                transition: {
                  ...arg.transition,
                  redraw: true,
                },
              })),
            })),
          })
        )

        setMapLayout({ ...JSON.parse(data[1]).layout, sliders: updatedSliders })
        setIsGettingGraph(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setExpandedAccordion(false)
          setFetchingErrors(error)
        }
        setIsGettingGraph(false)
      })
  }

  function clearFilters() {
    setFilteredAntibioticsList([])
    setSelectedOrganism({ id: "", name: "" })
    setSelectedAntibioticClass({ id: "", name: "" })
    setSelectedAntibiotic({ id: "", name: "" })
    setIsGraphReady(false)

    setGraphData([
      {
        type: "scatter",
        x: [1, 2, 3, 4],
        y: [10, 15, 13, 17],
        mode: "lines",
        name: "",
        line: {
          color: "rgb(173, 173, 173)",
          width: 3,
        },
      },
      {
        type: "scatter",
        x: [1, 2, 3, 4],
        y: [12, 9, 15, 12],
        mode: "lines",
        name: "",
        line: {
          color: "#B8B8B8",
          width: 1,
        },
      },
    ])
    setGraphLayout(null)
  }

  if (loading) {
    return (
      <>
        <TopBarPublic />
        <div className="public-dashboard-wrapper">
          <h1 className="public-dashboard-title">
            WhoNET Platform{" "}
            <span className="public-dashboard-title emphasis">
              | Public Dashboard{" "}
            </span>
          </h1>
          <p className="public-dashboard-subtitle">
            <Trans t={t} i18nKey="publicDashboard.statisticsTitle">
              Στατιστικά στοιχεία
            </Trans>
          </p>
          <div className="public-dashboard-container loading">
            <Skeleton variant="rounded" width={"100%"} height={"100%"} />
            <div className="public-dashboard-graphs-container">
              <Skeleton variant="rounded" width={"50%"} height={"100%"} />
              <Skeleton variant="rounded" width={"50%"} height={"100%"} />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <TopBarPublic />
      <div className="public-dashboard-wrapper">
        <h1 className="public-dashboard-title">
          WhoNET Platform{" "}
          <span className="public-dashboard-title emphasis">
            | Public Dashboard{" "}
          </span>
        </h1>
        <p className="public-dashboard-subtitle">
          <Trans t={t} i18nKey="publicDashboard.statisticsTitle">
            Στατιστικά στοιχεία
          </Trans>
        </p>
        <div className="public-dashboard-container">
          <div className="public-dashboard-filters">
            <Accordion expanded={expandedAccordion}>
              <AccordionSummary
                onClick={() => setExpandedAccordion(!expandedAccordion)}
                expandIcon={<ExpandMoreIcon />}
              >
                <h3 className="public-dashboard-box-titles">
                  <Trans t={t} i18nKey="publicDashboard.filtersTitle">
                    Φιλτράρισμα Δεδομένων
                  </Trans>
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <div className="public-dashboard-filters-select">
                  <p style={{ whiteSpace: "nowrap" }}>
                    <Trans t={t} i18nKey="publicDashboard.filterSelection">
                      Επιλογή κατηγορίας
                    </Trans>
                  </p>
                  <FormControl sx={{ m: 1, width: "100%" }} size="small">
                    <Select
                      value={filterType}
                      onChange={(e) => {
                        setFilteredAntibioticsList([])
                        setSelectedOrganism({ id: "", name: "" })
                        setSelectedAntibioticClass({ id: "", name: "" })
                        setSelectedAntibiotic({ id: "", name: "" })
                        setFilterType(e.target.value)
                        setIsGraphReady(false)
                      }}
                    >
                      <MenuItem value={1}>
                        <Trans
                          t={t}
                          i18nKey="publicDashboard.antibioticClassesTitle"
                        >
                          Αντιβιοτικά/Κλάσεις
                        </Trans>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Trans t={t} i18nKey="publicDashboard.antibioticsTitle">
                          Αντιβιοτικά
                        </Trans>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginTop: "-15px", marginBottom: "40px" }}>
                  <p className="public-dashboard-filters-titles">
                    <RotatingLines
                      visible={isGettingAntibioticsList}
                      height="22"
                      width="22"
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                    />
                    <Trans t={t} i18nKey="publicDashboard.organismsTitle">
                      Οργανισμοί
                    </Trans>
                  </p>
                  <hr />
                  <div className="public-dashboard-row-list ">
                    {organismsData.map((organism) => (
                      <p
                        className={
                          selectedOrganism.id === organism.orga_id
                            ? "public-dashboard-filter-element-organisms active"
                            : "public-dashboard-filter-element-organisms"
                        }
                        key={organism.orga_id}
                        onClick={() =>
                          handleFilterSelectionOrganism(
                            organism.orga_id,
                            organism.orga_name
                          )
                        }
                      >
                        {organism.orga_name}
                      </p>
                    ))}
                  </div>
                </div>

                <div style={{ marginBottom: "40px" }}>
                  {filterType === 1 ? (
                    <p className="public-dashboard-filters-titles">
                      <RotatingLines
                        visible={isGettingAntibioticsList}
                        height="22"
                        width="22"
                        strokeColor="black"
                        strokeWidth="5"
                        animationDuration="0.75"
                      />
                      <Trans
                        t={t}
                        i18nKey="publicDashboard.antibioticClassesTitle"
                      >
                        Αντιβιοτικά/Κλάσεις
                      </Trans>
                    </p>
                  ) : (
                    <p className="public-dashboard-filters-titles">
                      <RotatingLines
                        visible={isGettingAntibioticsList}
                        height="22"
                        width="22"
                        strokeColor="black"
                        strokeWidth="5"
                        animationDuration="0.75"
                      />
                      <Trans t={t} i18nKey="publicDashboard.antibioticsTitle">
                        Αντιβιοτικά
                      </Trans>
                    </p>
                  )}
                  <hr />
                  <div className="public-dashboard-row-list ">
                    {filterType === 1
                      ? antibioticClassesData.map((antiClass) => (
                          <p
                            className={
                              filteredAntibioticsList.includes(
                                antiClass.antcls_id
                              )
                                ? parseInt(selectedAntibioticClass.id) ===
                                  parseInt(antiClass.antcls_id)
                                  ? "public-dashboard-filter-element-antibiotics selected"
                                  : "public-dashboard-filter-element-antibiotics active"
                                : "public-dashboard-filter-element-antibiotics"
                            }
                            key={antiClass.antcls_id}
                            onClick={
                              filteredAntibioticsList.includes(
                                antiClass.antcls_id
                              ) && !isGettingGraph
                                ? () =>
                                    handleFilterSelectionAntibiotic(
                                      antiClass.antcls_id,
                                      antiClass.antcls_name
                                    )
                                : null
                            }
                          >
                            {antiClass.antcls_name}
                          </p>
                        ))
                      : antibioticsData.map((antibiotic) => (
                          <p
                            className={
                              filteredAntibioticsList.includes(
                                antibiotic.antib_id
                              )
                                ? parseInt(selectedAntibiotic.id) ===
                                  parseInt(antibiotic.antib_id)
                                  ? "public-dashboard-filter-element-antibiotics selected"
                                  : "public-dashboard-filter-element-antibiotics active"
                                : "public-dashboard-filter-element-antibiotics"
                            }
                            key={antibiotic.antib_id}
                            onClick={
                              filteredAntibioticsList.includes(
                                antibiotic.antib_id
                              ) && !isGettingGraph
                                ? () =>
                                    handleFilterSelectionAntibiotic(
                                      antibiotic.antib_id,
                                      antibiotic.antib_name
                                    )
                                : null
                            }
                          >
                            {antibiotic.antib_name}
                          </p>
                        ))}
                  </div>
                </div>
              </AccordionDetails>
              <AccordionActions>
                <>
                  <button
                    onClick={showGraph}
                    className="public-dashboard-graph-filters-btn"
                    disabled={
                      isGettingGraph ||
                      (selectedAntibioticClass.id === "" &&
                        selectedAntibiotic.id === "")
                    }
                  >
                    <RotatingLines
                      visible={isGettingGraph}
                      height="20"
                      width="20"
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                    />
                    {!isGettingGraph && (
                      <QueryStatsRoundedIcon fontSize="small" />
                    )}
                    <Trans t={t} i18nKey="publicDashboard.filtersGraphButton">
                      Εμφάνιση Γραφημάτων
                    </Trans>
                  </button>

                  <button
                    onClick={clearFilters}
                    className="public-dashboard-graph-filters-btn clear"
                  >
                    <RestartAltRoundedIcon fontSize="small" />
                    <Trans t={t} i18nKey="publicDashboard.filtersClearButton">
                      Καθαρισμός
                    </Trans>
                  </button>
                </>
              </AccordionActions>
            </Accordion>
          </div>
          <div className="public-dashboard-graphs-container">
            <div className="public-dashboard-graphs">
              <h3 className="public-dashboard-box-titles">
                {filterType === 1 ? (
                  <Trans t={t} i18nKey="publicDashboard.graphTitleOrgClass">
                    Γράφημα | Οργανισμού - Κλάση Αντιβιοτικού
                  </Trans>
                ) : (
                  <Trans t={t} i18nKey="publicDashboard.graphTitleOrgAntib">
                    Γράφημα | Οργανισμού - Αντιβιοτικού
                  </Trans>
                )}
              </h3>

              {isGraphReady && (
                <>
                  <div className="public-dashboard-graph-description">
                    <p>
                      <b>
                        <Trans t={t} i18nKey="publicDashboard.selectedOrganism">
                          Επιλεγμένος οργανισμός
                        </Trans>
                        :
                      </b>
                    </p>
                    <p>{selectedOrganism.name}</p>
                  </div>

                  {filterType === 1 ? (
                    <div className="public-dashboard-graph-description">
                      <b>
                        <p>
                          <Trans
                            t={t}
                            i18nKey="publicDashboard.selectedAntibioticClass"
                          >
                            Επιλεγμένη κλάση αντιβιοτικού
                          </Trans>
                          :
                        </p>
                      </b>
                      <p>{selectedAntibioticClass.name}</p>
                    </div>
                  ) : (
                    <div className="public-dashboard-graph-description">
                      <b>
                        <p>
                          <Trans
                            t={t}
                            i18nKey="publicDashboard.selectedAntibiotic"
                          >
                            Επιλεγμένο αντιβιοτικό
                          </Trans>
                          :
                        </p>
                      </b>
                      <p>{selectedAntibiotic.name}</p>
                    </div>
                  )}
                </>
              )}

              {!isGraphReady && (
                <div className="public-dashboard-graph-no-data">
                  {isGettingGraph ? (
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#31363f"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    <h3>
                      <Trans t={t} i18nKey="publicDashboard.selectDataMessage">
                        Επιλέξτε δεδομένα από τον πίνακα φίλτρων
                      </Trans>
                    </h3>
                  )}
                </div>
              )}

              <Plot
                style={{ width: "100%", height: "80vh", marginTop: "10px" }}
                data={graphData}
                layout={graphLayout}
                config={{
                  displaylogo: false,
                  displayModeBar: true,
                }}
                useResizeHandler={true}
              />
            </div>
            <div className="public-dashboard-graphs">
              <h3 className="public-dashboard-box-titles">
                {filterType === 1 ? (
                  <Trans t={t} i18nKey="publicDashboard.mapTitleOrgClass">
                    Χάρτης | Οργανισμού - Κλάση Αντιβιοτικού
                  </Trans>
                ) : (
                  <Trans t={t} i18nKey="publicDashboard.mapTitleOrgAntib">
                    Χάρτης | Οργανισμού - Αντιβιοτικού
                  </Trans>
                )}
              </h3>

              {/* TODO: remove duplicate code */}
              {isGraphReady && (
                <>
                  <div className="public-dashboard-graph-description">
                    <p>
                      <b>
                        <Trans t={t} i18nKey="publicDashboard.selectedOrganism">
                          Επιλεγμένος οργανισμός
                        </Trans>
                        :
                      </b>
                    </p>
                    <p>{selectedOrganism.name}</p>
                  </div>

                  {filterType === 1 ? (
                    <div className="public-dashboard-graph-description">
                      <b>
                        <p>
                          <Trans
                            t={t}
                            i18nKey="publicDashboard.selectedAntibioticClass"
                          >
                            Επιλεγμένη κλάση αντιβιοτικού
                          </Trans>
                          :
                        </p>
                      </b>
                      <p>{selectedAntibioticClass.name}</p>
                    </div>
                  ) : (
                    <div className="public-dashboard-graph-description">
                      <b>
                        <p>
                          <Trans
                            t={t}
                            i18nKey="publicDashboard.selectedAntibiotic"
                          >
                            Επιλεγμένο αντιβιοτικό
                          </Trans>
                          :
                        </p>
                      </b>
                      <p>{selectedAntibiotic.name}</p>
                    </div>
                  )}
                </>
              )}

              {!isGraphReady && (
                <div className="public-dashboard-graph-no-data">
                  {isGettingGraph ? (
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#31363f"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    <h3>
                      <Trans t={t} i18nKey="publicDashboard.selectDataMessage">
                        Επιλέξτε δεδομένα από τον πίνακα φίλτρων
                      </Trans>
                    </h3>
                  )}
                </div>
              )}

              <Plot
                style={{ width: "100%", height: "80vh", marginTop: "10px" }}
                data={mapData}
                layout={mapLayout}
                frame={frameData}
                config={{
                  displaylogo: false,
                  displayModeBar: true,
                }}
                useResizeHandler={true}
                onButtonClicked={(e) => {
                  if (e.button.label === "&#9654;") {
                    for (let i = 0; i <= 4; i++) {
                      if (i === 4) {
                        setMapData(mapData)
                        setMapLayout((prevLayout) => {
                          return {
                            ...prevLayout,
                            sliders: [
                              {
                                ...prevLayout.sliders[0],
                                active: 0,
                              },
                            ],
                          }
                        })
                      } else {
                        setTimeout(() => {
                          setMapData(frameData[i].data)

                          setMapLayout((prevLayout) => {
                            return {
                              ...prevLayout,
                              sliders: [
                                {
                                  ...prevLayout.sliders[0],
                                  active: i,
                                },
                              ],
                            }
                          })
                        }, 1000 * i)
                      }
                    }
                  }
                }}
                onSliderChange={(e) => {
                  setMapData(frameData[e.slider.active].data)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

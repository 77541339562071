import React from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import "./UploadFileSteps.css"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

export default function UploadFileStepOne(props) {
  const { t } = useTranslation()

  return (
    <>
      <div className="upload-file-row">
        <div style={{ width: "50%" }}>
          <p>
            <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.dateFrom">
              Χρονικό διάστημα <b>από</b>
            </Trans>
            :
          </p>
          <DatePicker
            maxDate={dayjs()}
            slotProps={{ textField: { size: "small" } }}
            value={props.dateFrom}
            onChange={(e) => props.handleFormChanges(e, "dateFrom")}
            sx={{ width: "100%", backgroundColor: "#F9F9FB" }}
            format={"DD/MM/YYYY"}
          />
        </div>

        <div style={{ width: "50%" }}>
          <p>
            <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.dateTo">
              Χρονικό διάστημα <b>έως</b>
            </Trans>
            :
          </p>
          <DatePicker
            maxDate={dayjs()}
            minDate={props.dateFrom}
            slotProps={{ textField: { size: "small" } }}
            value={props.dateTo}
            onChange={(e) => props.handleFormChanges(e, "dateTo")}
            sx={{ width: "100%", backgroundColor: "#F9F9FB" }}
            format={"DD/MM/YYYY"}
          />
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <div className="upload-file-row">
          <div style={{ width: props.type === "99" ? "50%" : "100%" }}>
            <p>
              <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.type">
                Τύπος
              </Trans>
              :
            </p>
            <Select
              value={props.type}
              displayEmpty
              onChange={(e) => props.handleFormChanges(e, "type")}
              sx={{
                width: "100%",
                height: "43px",
                backgroundColor: "#F9F9FB",
                color:
                  props.type === null ? "rgba(121, 121, 121, 0.6)" : "black",
              }}
            >
              <MenuItem
                slotProps={{ textField: { size: "small" } }}
                value={null}
              >
                <em>
                  <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.type">
                    Τύπος
                  </Trans>
                </em>
              </MenuItem>

              <MenuItem slotProps={{ textField: { size: "small" } }} value="1">
                LIS
              </MenuItem>
              <MenuItem value="2">Automated analyser</MenuItem>
              <MenuItem value="3">MYLA</MenuItem>
              <MenuItem value="4">ΕΠΣΜΥ</MenuItem>
              <MenuItem value="99">Άλλος... (προσδιορίστε)</MenuItem>
            </Select>
          </div>

          {props.type === "99" && (
            <div style={{ width: "50%" }}>
              <p>
                <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.typeOther">
                  Προσδιορίστε τύπο
                </Trans>
                :
              </p>

              <TextField
                className="upload-file-text-field"
                hiddenLabel
                variant="outlined"
                size="small"
                value={props.typeOther}
                sx={{ width: "100%" }}
                placeholder={t(
                  "uploadFile.uploadFileStepOne.typeOtherPlaceholder"
                )}
                onChange={(e) => props.handleFormChanges(e, "typeOther")}
                title={t("uploadFile.uploadFileStepOne.typeOtherPlaceholder")}
              />
            </div>
          )}
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <p>
          <Trans t={t} i18nKey="uploadFile.uploadFileStepOne.comments">
            Σχόλια
          </Trans>
          :
        </p>
        <textarea
          onChange={(e) => props.handleFormChanges(e, "comments")}
          placeholder={t("uploadFile.uploadFileStepOne.commentsPlaceholder")}
        ></textarea>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import "./BiologicalData.css"
import { Oval } from "react-loader-spinner"
import Alert from "@mui/material/Alert"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { apiCaller } from "../../Api/ApiCaller"
import VaccinesRoundedIcon from "@mui/icons-material/VaccinesRounded"

export default function BiologicalData() {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [fetchingErrors, setFetchingErrors] = useState("")
  const [organismsData, setOrganismsData] = useState([])
  const [antibioticsData, setAntibioticsData] = useState([])
  const [antibioticClassesData, setAntibioticClassesData] = useState([])
  const [antibioticSubClassesData, setAntibioticSubClassesData] = useState([])

  useEffect(() => {
    setLoading(true)
    setFetchingErrors("")
    Promise.all([
      apiCaller(
        `${process.env.REACT_APP_IP}/organisms`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotics`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotic-classes`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotic-subclasses`,
        "GET",
        "application/json",
        null,
        true
      ),
    ])
      .then((jsonResponse) => {
        setOrganismsData(jsonResponse[0])
        setAntibioticsData(jsonResponse[1])
        setAntibioticClassesData(jsonResponse[2])
        setAntibioticSubClassesData(jsonResponse[3])
        setLoading(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
          setLoading(false)
        }
      })
  }, [])

  if (fetchingErrors !== "") {
    return (
      <div className="bio-data-wrapper">
        <p className="bio-data-title">
          <VaccinesRoundedIcon /> {t("bioData.title")}
        </p>
        <Alert severity="error" variant="filled">
          {fetchingErrors.message}
        </Alert>
      </div>
    )
  }

  return (
    <div className="bio-data-wrapper">
      <p className="bio-data-title">
        <VaccinesRoundedIcon />{" "}
        <Trans t={t} i18nKey="bioData.title">
          Bugs & Drugs
        </Trans>
      </p>
      <div className="bio-data-boxes-container">
        <div className="bio-data-boxes-row">
          <div style={{ width: "100%" }} className="bio-data-boxes">
            <h3 className="bio-data-boxes-header">
              <Trans t={t} i18nKey="bioData.organismsTitle">
                Οργανισμοί
              </Trans>
            </h3>
            <hr />

            {!loading ? (
              <div className="bio-data-boxes-tables">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>
                        <Trans t={t} i18nKey="bioData.organismsTable.id">
                          ID
                        </Trans>
                      </th>
                      <th>
                        <Trans t={t} i18nKey="bioData.organismsTable.name">
                          Όνομασία
                        </Trans>
                      </th>
                      <th style={{ width: "10%" }}>
                        <Trans t={t} i18nKey="bioData.organismsTable.code">
                          Κωδικός
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {organismsData.map((organism) => (
                      <tr key={organism.orga_id}>
                        <td>{organism.orga_id}</td>
                        <td>{organism.orga_name}</td>
                        <td>{organism.orga_code}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bio-data-boxes-loading">
                <Oval
                  visible={true}
                  height="50"
                  width="50"
                  color="black"
                  secondaryColor="grey"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </div>
            )}
          </div>
        </div>

        <div className="bio-data-boxes-row">
          <div style={{ width: "55%" }} className="bio-data-boxes">
            <h3 className="bio-data-boxes-header">
              <Trans t={t} i18nKey="bioData.antibioticsTitle">
                Αντιβιοτικά
              </Trans>
            </h3>
            <hr />

            {!loading ? (
              <div className="bio-data-boxes-tables">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>
                        <Trans t={t} i18nKey="bioData.antibioticsTable.id">
                          ID
                        </Trans>
                      </th>
                      <th>
                        <Trans t={t} i18nKey="bioData.antibioticsTable.name">
                          Όνομασία
                        </Trans>
                      </th>
                      <th style={{ width: "30%" }}>
                        <Trans
                          t={t}
                          i18nKey="bioData.antibioticsTable.subClassCode"
                        >
                          Κωδικός Υποκλάσης
                        </Trans>
                      </th>
                      <th style={{ width: "10%" }}>
                        <Trans t={t} i18nKey="bioData.antibioticsTable.code">
                          Κωδικός
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {antibioticsData.map((antibiotic) => (
                      <tr key={antibiotic.antib_id}>
                        <td>{antibiotic.antib_id}</td>
                        <td>{antibiotic.antib_name}</td>
                        <td>{antibiotic.antib_antsub_id}</td>
                        <td>{antibiotic.antib_code}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bio-data-boxes-loading">
                <Oval
                  visible={true}
                  height="50"
                  width="50"
                  color="black"
                  secondaryColor="grey"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </div>
            )}
          </div>

          <div style={{ width: "45%" }} className="bio-data-boxes-container">
            <div className="bio-data-sub-boxes">
              <h3 className="bio-data-boxes-header">
                <Trans t={t} i18nKey="bioData.antibioticsClassesTitle">
                  Αντιβιοτικά - Κλάσεις
                </Trans>
              </h3>
              <hr />

              {!loading ? (
                <div className="bio-data-boxes-tables">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>
                          <Trans
                            t={t}
                            i18nKey="bioData.antibioticsClassesTable.id"
                          >
                            ID
                          </Trans>
                        </th>
                        <th>
                          <Trans
                            t={t}
                            i18nKey="bioData.antibioticsClassesTable.name"
                          >
                            Ονομασία
                          </Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {antibioticClassesData.map((antClass) => (
                        <tr key={antClass.antcls_id}>
                          <td>{antClass.antcls_id}</td>
                          <td>{antClass.antcls_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="bio-data-boxes-loading">
                  <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="black"
                    secondaryColor="grey"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              )}
            </div>

            <div className="bio-data-sub-boxes">
              <h3 className="bio-data-boxes-header">
                <Trans t={t} i18nKey="bioData.antibioticSubClassesTitle">
                  Αντιβιοτικά - Υποκλάσεις
                </Trans>
              </h3>
              <hr />

              {!loading ? (
                <div className="bio-data-boxes-tables">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>
                          <Trans
                            t={t}
                            i18nKey="bioData.antibioticSubClassesTable.id"
                          >
                            ID
                          </Trans>
                        </th>
                        <th>
                          <Trans
                            t={t}
                            i18nKey="bioData.antibioticSubClassesTable.name"
                          >
                            Όνομασία
                          </Trans>
                        </th>
                        <th style={{ width: "35%" }}>
                          <Trans
                            t={t}
                            i18nKey="bioData.antibioticSubClassesTable.classCode"
                          >
                            Κωδικός Κλάσης
                          </Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {antibioticSubClassesData.map((antSubClass) => (
                        <tr key={antSubClass.antsub_id}>
                          <td>{antSubClass.antsub_id}</td>
                          <td>{antSubClass.antsub_name}</td>
                          <td>{antSubClass.antsub_antcls_id}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="bio-data-boxes-loading">
                  <Oval
                    visible={true}
                    height="50"
                    width="50"
                    color="black"
                    secondaryColor="grey"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import "./TopBar.css"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import LanguageIcon from "@mui/icons-material/Language"
import { changeLanguage } from "i18next"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import Logout from "@mui/icons-material/Logout"

export default function TopBar() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const { t } = useTranslation()

  function logout() {
    localStorage.clear()
    window.location.reload()
  }

  function handleChangeLanguage() {
    const newLanguage = localStorage.getItem("locale") === "en" ? "gr" : "en"
    localStorage.setItem("locale", newLanguage)
    changeLanguage(newLanguage)
  }

  return (
    <div>
      <div className="topBar-wrapper">
        <div className="topBar-logo-container">
          <img
            width="35"
            height="35"
            src="https://img.icons8.com/fluency/96/virus.png"
            alt="virus"
          />
          <p className="topBar-logo">WhoNET Platform</p>
        </div>
        <div className="topBar-right-container">
          <p className="topBar-right-contents">
            <AccountCircleRoundedIcon /> {userInfo?.username}
          </p>
          <p
            onClick={handleChangeLanguage}
            className="topBar-right-contents action"
          >
            <LanguageIcon />{" "}
            <Trans t={t} i18nKey="topBar.language">
              English
            </Trans>
          </p>
          <p onClick={logout} className="topBar-right-contents action">
            <Logout />{" "}
            <Trans t={t} i18nKey="topBar.logout">
              Έξοδος
            </Trans>
          </p>
        </div>
      </div>
    </div>
  )
}

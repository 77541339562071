import React, { useState } from "react"
import "./UploadFile.css"
import { useNavigate } from "react-router-dom"
import UploadFileAdmin from "./UploadFileAdmin"
import UploadFileUser from "./UploadFileUser"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import uploadRawFile from "../../Api/FilesUploader"

export default function UploadFile() {
  const [activeStep, setActiveStep] = useState(0)
  const [completedSteps, setCompletedSteps] = useState([false, false, false])
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [type, setType] = useState(null)
  const [typeOther, setTypeOther] = useState("")
  const [comments, setComments] = useState("")
  const [file, setFile] = useState(null)
  const [checkFinished, setCheckFinished] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadError, setUploadError] = useState("")
  const navigate = useNavigate()
  const { t } = useTranslation()

  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const isAdmin = userInfo.scopes.includes("admin") || false

  function handleNext() {
    if (activeStep === 1 && isAdmin) {
      console.log(file)
      const formData = new FormData()
      formData.append("file", file)
      setLoadingUpload(true)
      uploadRawFile(file)
        .then(() => {
          localStorage.setItem("fileUploaded", true)
          navigate("/files/all", { replace: true, state: { from: "upload" } })
        })
        .catch((error) => {
          if (error.message === "401") {
            alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
            localStorage.clear()
            window.location.reload()
          } else {
            setUploadError(error)
            setLoadingUpload(false)
          }
        })
    } else if (activeStep === 2) {
      const formData = new FormData()
      formData.append("file", file)
      setLoadingUpload(true)
      uploadRawFile(file)
        .then(() => {
          localStorage.setItem("fileUploaded", true)
          navigate("/files/all", { replace: true, state: { from: "upload" } })
        })
        .catch((error) => {
          if (error.message === "401") {
            alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
            localStorage.clear()
            window.location.reload()
          } else {
            setUploadError(error)
            setLoadingUpload(false)
          }
        })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setCompletedSteps((prevCompletedSteps) => {
        const newCompletedSteps = [...prevCompletedSteps]
        newCompletedSteps[activeStep] = true
        return newCompletedSteps
      })
    }
  }

  function handleReset() {
    setActiveStep(0)
    setDateFrom(null)
    setDateTo(null)
    setType(null)
    setFile(null)
    setCompletedSteps([false, false, false])
  }

  function handleFormChanges(e, type) {
    if (type === "dateFrom") {
      setDateFrom(e)
    } else if (type === "dateTo") {
      setDateTo(e)
    } else if (type === "type") {
      setType(e.target.value)
    } else if (type === "typeOther") {
      setTypeOther(e.target.value)
    } else if (type === "comments") {
      setComments(e.target.value)
    }
  }

  return (
    <div className="upload-file-wrapper">
      <h2 className="upload-file-title">
        <Trans t={t} i18nKey="uploadFile.title">
          Ανέβασμα αρχείου
        </Trans>
      </h2>
      <hr />
      <div className="upload-file-container">
        {isAdmin ? (
          <UploadFileAdmin
            activeStep={activeStep}
            completedSteps={completedSteps}
            isAdmin={isAdmin}
            setCheckFinished={setCheckFinished}
            checkFinished={checkFinished}
            handleNext={handleNext}
            file={file}
            setFile={setFile}
            loadingUpload={loadingUpload}
            uploadError={uploadError}
            handleReset={handleReset}
          />
        ) : (
          <UploadFileUser
            activeStep={activeStep}
            completedSteps={completedSteps}
            dateFrom={dateFrom}
            dateTo={dateTo}
            type={type}
            typeOther={typeOther}
            comments={comments}
            handleFormChanges={handleFormChanges}
            setCheckFinished={setCheckFinished}
            checkFinished={checkFinished}
            handleNext={handleNext}
            file={file}
            setFile={setFile}
            loadingUpload={loadingUpload}
            uploadError={uploadError}
            handleReset={handleReset}
          />
        )}
      </div>
    </div>
  )
}

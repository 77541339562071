export async function apiCaller(
  url,
  method,
  contentType,
  body,
  isResponseJSON,
  type,
  fileName
) {
  try {
    const accessToken = localStorage.getItem("accessToken")

    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${accessToken}`,
      },
      body: method === "GET" && body === null ? null : body,
    })

    if (!response.ok) {
      const jsonResponse = await response.json()
      const status = response.status
      switch (status) {
        case 400:
          if (type === "antibioticsList") {
            return []
          } else {
            throw new Error(jsonResponse.detail)
          }
        case 401:
          throw new Error("401")
        default:
          throw new Error("Άγνωστο σφάλμα. Δοκιμάστε ξανά.")
      }
    }

    if (isResponseJSON) {
      const jsonResponse = await response.json()
      if (type === "login") {
        localStorage.setItem("accessToken", jsonResponse.access_token)
        localStorage.setItem("locale", "en")
      } else if (type === "me") {
        localStorage.setItem("userInfo", JSON.stringify(jsonResponse))
      } else if (type === "institutions") {
        localStorage.setItem("institutions", JSON.stringify(jsonResponse))
      }
      return jsonResponse
    } else {
      if (type === "downloadFile") {
        const blob = await response.blob()
        const link = document.createElement("a")
        link.style.display = "none"
        link.href = window.URL.createObjectURL(blob)
        link.download = `${fileName}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (type === "pygwalker") {
        const jsonResponse = await response.text()
        return jsonResponse
      }
    }
  } catch (error) {
    console.error("Fetching error:", error)
    if (!error.message) {
      throw new Error("Αποτυχία σύνδεσης. Δοκιμάστε ξανά.")
    } else {
      throw new Error(error.message)
    }
  }
}

import React, { useEffect, useState } from "react"
import "./Home.css"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import { Oval, RotatingLines } from "react-loader-spinner"
import Alert from "@mui/material/Alert"
import Plot from "react-plotly.js"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionActions from "@mui/material/AccordionActions"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { apiCaller } from "../../Api/ApiCaller"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded"
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded"

export default function Home() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const fullName = userInfo.full_name
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [fetchingErrors, setFetchingErrors] = useState("")
  const [organismsData, setOrganismsData] = useState([])
  const [antibioticsData, setAntibioticsData] = useState([])
  const [antibioticClassesData, setAntibioticClassesData] = useState([])
  const [filteredAntibioticsList, setFilteredAntibioticsList] = useState([])
  const [graphData, setGraphData] = useState(null)
  const [graphLayout, setGraphLayout] = useState({})
  const [selectedOrganism, setSelectedOrganism] = useState({ id: "", name: "" })
  const [selectedAntibioticClass, setSelectedAntibioticClass] = useState({
    id: "",
    name: "",
  })
  const [selectedAntibiotic, setSelectedAntibiotic] = useState({
    id: "",
    name: "",
  })
  const [fetchedGraphs, setFetchedGraphs] = useState(null)
  const [isGettingAntibioticsList, setIsGettingAntibioticsList] =
    useState(false)
  const [isGettingGraph, setIsGettingGraph] = useState(false)
  const [expandedAccordion, setExpandedAccordion] = useState(true)
  const [filterType, setFilterType] = useState(1)

  const isAdmin = userInfo.scopes.includes("admin") || false

  const [mapData, setMapData] = useState([])
  const [mapLayout, setMapLayout] = useState([])
  const [frameData, setFrameData] = useState([])

  useEffect(() => {
    setLoading(true)
    setFetchingErrors("")
    Promise.all([
      apiCaller(
        `${process.env.REACT_APP_IP}/organisms`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotic-classes`,
        "GET",
        "application/json",
        null,
        true
      ),
      apiCaller(
        `${process.env.REACT_APP_IP}/antibiotics`,
        "GET",
        "application/json",
        null,
        true
      ),
    ])
      .then((jsonResponse) => {
        setOrganismsData(jsonResponse[0])
        setAntibioticClassesData(jsonResponse[1])
        setAntibioticsData(jsonResponse[2])
        setFetchedGraphs(jsonResponse[3])

        setLoading(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
          setLoading(false)
        }
      })
  }, [])

  function handleFilterSelectionOrganism(id, name) {
    setGraphData(null)
    setSelectedOrganism({ id: id, name: name })
    setFetchingErrors("")
    setIsGettingAntibioticsList(true)

    let url = ""
    if (filterType === 1) {
      url = `${process.env.REACT_APP_IP}/classes-from-organism/${id}`
    } else {
      url = `${process.env.REACT_APP_IP}/antibiotics-from-organism/${id}`
    }

    apiCaller(url, "GET", "application/json", null, true, "antibioticsList")
      .then((jsonResponse) => {
        setIsGettingAntibioticsList(false)
        setFilteredAntibioticsList(jsonResponse)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
        }
        setIsGettingAntibioticsList(false)
      })
  }

  function handleFilterSelectionAntibiotic(id, name) {
    setGraphData(null)
    if (filterType === 1) {
      setSelectedAntibioticClass({ id: id, name: name })
      setSelectedAntibiotic({ id: "", name: "" })
    } else {
      setSelectedAntibiotic({ id: id, name: name })
      setSelectedAntibioticClass({ id: "", name: "" })
    }
  }

  function showGraph() {
    setIsGettingGraph(true)
    const type1 = isAdmin ? "institutions" : "departments"

    let url1 = ""
    let url2 = ""

    if (filterType === 1) {
      url1 = `${process.env.REACT_APP_IP}/graph2/${type1}/class/${selectedOrganism.id}/${selectedAntibioticClass.id}`
      url2 = `${process.env.REACT_APP_IP}/public-map1/regions/class/${selectedOrganism.id}/${selectedAntibioticClass.id}`
    } else {
      url1 = `${process.env.REACT_APP_IP}/graph2/${type1}/antibiotic/${selectedOrganism.id}/${selectedAntibiotic.id}`
      url2 = `${process.env.REACT_APP_IP}/public-map1/regions/antibiotic/${selectedOrganism.id}/${selectedAntibiotic.id}`
    }

    Promise.all([
      apiCaller(url1, "GET", "application/json", null, true),
      apiCaller(url2, "GET", "application/json", null, true),
    ])
      .then((data) => {
        // GRAPH 1 DATA
        setGraphData(JSON.parse(data[0]).data)
        setGraphLayout(JSON.parse(data[0]).layout)

        // MAP DATA
        // console.log(JSON.parse(data[1]))
        setMapData(JSON.parse(data[1]).frames[3].data)
        setFrameData(JSON.parse(data[1]).frames)
        const updatedSliders = JSON.parse(data[1]).layout.sliders.map(
          (item) => ({
            ...item,
            active: 3,
            steps: item.steps.map((step) => ({
              ...step,
              args: step.args.map((arg) => ({
                ...arg,
                transition: {
                  ...arg.transition,
                  redraw: true,
                },
              })),
            })),
          })
        )

        setMapLayout({ ...JSON.parse(data[1]).layout, sliders: updatedSliders })

        setIsGettingGraph(false)
        setExpandedAccordion(false)
        setIsGettingGraph(false)
      })
      .catch((error) => {
        if (error.message === "401") {
          alert("Ο χρόνος σύνδεσης έληξε. Παρακαλώ συνδεθείτε ξανά.")
          localStorage.clear()
          window.location.reload()
        } else {
          setFetchingErrors(error)
        }
        setIsGettingGraph(false)
      })
  }

  function clearFilters() {
    setFilteredAntibioticsList([])
    setSelectedOrganism({ id: "", name: "" })
    setSelectedAntibioticClass({ id: "", name: "" })
    setSelectedAntibiotic({ id: "", name: "" })

    setGraphData(null)
    setGraphLayout(null)
  }

  if (loading) {
    return (
      <div className="home-wrapper">
        <p className="home-welcome-title">
          <AssignmentIndIcon />{" "}
          <Trans t={t} i18nKey="home.welcomeMessage">
            Καλώς ορίσατε, {{ fullName }}!
          </Trans>
        </p>
        <div className="home-boxes loading">
          <Oval
            visible={true}
            height="50"
            width="50"
            color="black"
            secondaryColor="grey"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      </div>
    )
  }

  if (fetchingErrors !== "") {
    return (
      <div className="home-wrapper">
        <p className="home-welcome-title">
          <AssignmentIndIcon />{" "}
          <Trans t={t} i18nKey="home.welcomeMessage">
            Καλώς ορίσατε, {{ fullName }}!
          </Trans>
        </p>

        <Alert severity="error" variant="filled">
          {fetchingErrors.message}
        </Alert>
      </div>
    )
  }

  return (
    <div className="home-wrapper">
      <p className="home-welcome-title">
        <AssignmentIndIcon />
        <Trans t={t} i18nKey="home.welcomeMessage">
          Καλώς ορίσατε, {{ fullName }}!
        </Trans>
      </p>
      <div className="home-accordion-container">
        <Accordion expanded={expandedAccordion}>
          <AccordionSummary
            onClick={() => setExpandedAccordion(!expandedAccordion)}
            expandIcon={<ExpandMoreIcon />}
          >
            <h3 className="home-boxes-title">
              {
                <Trans t={t} i18nKey="home.filtersTitle">
                  Φιλτράρισμα Δεδομένων
                </Trans>
              }
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="home-filters-select">
                <p>
                  <Trans t={t} i18nKey="home.filterSelection">
                    Επιλογή κατηγορίας
                  </Trans>
                </p>
                <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                  <Select
                    value={filterType}
                    onChange={(e) => {
                      setFilteredAntibioticsList([])
                      setSelectedOrganism({ id: "", name: "" })
                      setSelectedAntibioticClass({ id: "", name: "" })
                      setSelectedAntibiotic({ id: "", name: "" })
                      setFilterType(e.target.value)
                    }}
                  >
                    <MenuItem value={1}>
                      <Trans t={t} i18nKey="home.antibioticClassesTitle">
                        Αντιβιοτικά/Κλάσεις
                      </Trans>
                    </MenuItem>
                    <MenuItem value={2}>
                      <Trans t={t} i18nKey="home.antibioticsTitle">
                        Αντιβιοτικά
                      </Trans>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginTop: "-15px", marginBottom: "40px" }}>
                <p className="home-filters-titles">
                  <RotatingLines
                    visible={isGettingAntibioticsList}
                    height="22"
                    width="22"
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                  />
                  <Trans t={t} i18nKey="home.organismsTitle">
                    Οργανισμοί
                  </Trans>
                </p>
                <hr />
                <div className="home-boxes-row-list">
                  {organismsData.map((organism) => (
                    <p
                      className={
                        selectedOrganism.id === organism.orga_id
                          ? "home-boxes-filter-element-organisms active"
                          : "home-boxes-filter-element-organisms"
                      }
                      key={organism.orga_id}
                      onClick={() =>
                        handleFilterSelectionOrganism(
                          organism.orga_id,
                          organism.orga_name
                        )
                      }
                    >
                      {organism.orga_name}
                    </p>
                  ))}
                </div>
              </div>

              <div style={{ marginBottom: "40px" }}>
                {filterType === 1 ? (
                  <p className="home-filters-titles">
                    <RotatingLines
                      visible={isGettingAntibioticsList}
                      height="22"
                      width="22"
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                    />
                    <Trans t={t} i18nKey="home.antibioticClassesTitle">
                      Αντιβιοτικά/Κλάσεις
                    </Trans>
                  </p>
                ) : (
                  <p className="home-filters-titles">
                    <RotatingLines
                      visible={isGettingAntibioticsList}
                      height="22"
                      width="22"
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                    />
                    <Trans t={t} i18nKey="home.antibioticsTitle">
                      Αντιβιοτικά
                    </Trans>
                  </p>
                )}
                <hr />
                <div className="home-boxes-row-list">
                  {filterType === 1
                    ? antibioticClassesData.map((antiClass) => (
                        <p
                          className={
                            filteredAntibioticsList.includes(
                              antiClass.antcls_id
                            )
                              ? parseInt(selectedAntibioticClass.id) ===
                                parseInt(antiClass.antcls_id)
                                ? "home-boxes-filter-element-antibiotics selected"
                                : "home-boxes-filter-element-antibiotics active"
                              : "home-boxes-filter-element-antibiotics"
                          }
                          key={antiClass.antcls_id}
                          onClick={
                            filteredAntibioticsList.includes(
                              antiClass.antcls_id
                            ) && !isGettingGraph
                              ? () =>
                                  handleFilterSelectionAntibiotic(
                                    antiClass.antcls_id,
                                    antiClass.antcls_name
                                  )
                              : null
                          }
                        >
                          {antiClass.antcls_name}
                        </p>
                      ))
                    : antibioticsData.map((antibiotic) => (
                        <p
                          className={
                            filteredAntibioticsList.includes(
                              antibiotic.antib_id
                            )
                              ? parseInt(selectedAntibiotic.id) ===
                                parseInt(antibiotic.antib_id)
                                ? "home-boxes-filter-element-antibiotics selected"
                                : "home-boxes-filter-element-antibiotics active"
                              : "home-boxes-filter-element-antibiotics"
                          }
                          key={antibiotic.antib_id}
                          onClick={
                            filteredAntibioticsList.includes(
                              antibiotic.antib_id
                            ) && !isGettingGraph
                              ? () =>
                                  handleFilterSelectionAntibiotic(
                                    antibiotic.antib_id,
                                    antibiotic.antib_name
                                  )
                              : null
                          }
                        >
                          {antibiotic.antib_name}
                        </p>
                      ))}
                </div>
              </div>
            </div>
          </AccordionDetails>
          <AccordionActions>
            <>
              <button
                onClick={showGraph}
                className="home-graph-filters-btn"
                disabled={
                  isGettingGraph ||
                  (selectedAntibioticClass.id === "" &&
                    selectedAntibiotic.id === "")
                }
              >
                <RotatingLines
                  visible={isGettingGraph}
                  height="20"
                  width="20"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                />
                {!isGettingGraph && <QueryStatsRoundedIcon fontSize="small" />}
                <Trans t={t} i18nKey="home.filtersGraphButton">
                  Εμφάνιση Γραφημάτων
                </Trans>
              </button>

              <button
                onClick={clearFilters}
                className="home-graph-filters-btn clear"
              >
                <RestartAltRoundedIcon fontSize="small" />
                <Trans t={t} i18nKey="home.filtersClearButton">
                  Καθαρισμός
                </Trans>
              </button>
            </>
          </AccordionActions>
        </Accordion>
      </div>

      <div className="home-boxes-graphs-container">
        {graphData !== null && (
          <div style={{ width: "50%" }} className="home-boxes">
            <p className="home-boxes-title">
              {filterType === 1 ? (
                <Trans t={t} i18nKey="home.graphTitleOrgClass">
                  Γράφημα | Οργανισμού - Κλάση Αντιβιοτικού
                </Trans>
              ) : (
                <Trans t={t} i18nKey="home.graphTitleOrgAntib">
                  Γράφημα | Οργανισμού - Αντιβιοτικού
                </Trans>
              )}
            </p>

            <>
              <div className="public-dashboard-graph-description">
                <p>
                  <b>
                    <Trans t={t} i18nKey="home.selectedOrganism">
                      Επιλεγμένος οργανισμός
                    </Trans>
                    :
                  </b>
                </p>
                <p>{selectedOrganism.name}</p>
              </div>

              {filterType === 1 ? (
                <div className="public-dashboard-graph-description">
                  <b>
                    <p>
                      <Trans t={t} i18nKey="home.selectedAntibioticClass">
                        Επιλεγμένη κλάση αντιβιοτικού
                      </Trans>
                      :
                    </p>
                  </b>
                  <p>{selectedAntibioticClass.name}</p>
                </div>
              ) : (
                <div className="public-dashboard-graph-description">
                  <b>
                    <p>
                      <Trans t={t} i18nKey="home.selectedAntibiotic">
                        Επιλεγμένο αντιβιοτικό
                      </Trans>
                      :
                    </p>
                  </b>
                  <p>{selectedAntibiotic.name}</p>
                </div>
              )}
            </>

            <div className="home-boxes-graph-container">
              <Plot
                style={{ width: "100%", height: "80vh", marginTop: "10px" }}
                data={graphData}
                layout={graphLayout}
                config={{
                  displaylogo: false,
                  displayModeBar: true,
                }}
                useResizeHandler={true}
              />
            </div>
          </div>
        )}

        {graphData !== null && (
          <div style={{ width: "50%" }} className="home-boxes">
            <p className="home-boxes-title">
              {filterType === 1 ? (
                <Trans t={t} i18nKey="home.mapTitleOrgClass">
                  Χάρτης | Οργανισμού - Κλάση Αντιβιοτικού
                </Trans>
              ) : (
                <Trans t={t} i18nKey="home.mapTitleOrgAntib">
                  Χάρτης | Οργανισμού - Αντιβιοτικού
                </Trans>
              )}
            </p>

            <>
              <div className="public-dashboard-graph-description">
                <p>
                  <b>
                    <Trans t={t} i18nKey="home.selectedOrganism">
                      Επιλεγμένος οργανισμός
                    </Trans>
                    :
                  </b>
                </p>
                <p>{selectedOrganism.name}</p>
              </div>

              {filterType === 1 ? (
                <div className="public-dashboard-graph-description">
                  <b>
                    <p>
                      <Trans t={t} i18nKey="home.selectedAntibioticClass">
                        Επιλεγμένη κλάση αντιβιοτικού
                      </Trans>
                      :
                    </p>
                  </b>
                  <p>{selectedAntibioticClass.name}</p>
                </div>
              ) : (
                <div className="public-dashboard-graph-description">
                  <b>
                    <p>
                      <Trans t={t} i18nKey="home.selectedAntibiotic">
                        Επιλεγμένο αντιβιοτικό
                      </Trans>
                      :
                    </p>
                  </b>
                  <p>{selectedAntibiotic.name}</p>
                </div>
              )}
            </>

            <div className="home-boxes-graph-container">
              <Plot
                style={{ width: "100%", height: "80vh", marginTop: "10px" }}
                data={mapData}
                layout={mapLayout}
                frame={frameData}
                config={{
                  displaylogo: false,
                  displayModeBar: true,
                }}
                useResizeHandler={true}
                onButtonClicked={(e) => {
                  if (e.button.label === "&#9654;") {
                    for (let i = 0; i <= 4; i++) {
                      if (i === 4) {
                        setMapData(mapData)
                        setMapLayout((prevLayout) => {
                          return {
                            ...prevLayout,
                            sliders: [
                              {
                                ...prevLayout.sliders[0],
                                active: 0,
                              },
                            ],
                          }
                        })
                      } else {
                        setTimeout(() => {
                          setMapData(frameData[i].data)

                          setMapLayout((prevLayout) => {
                            return {
                              ...prevLayout,
                              sliders: [
                                {
                                  ...prevLayout.sliders[0],
                                  active: i,
                                },
                              ],
                            }
                          })
                        }, 1000 * i)
                      }
                    }
                  }
                }}
                onSliderChange={(e) => {
                  setMapData(frameData[e.slider.active].data)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import english from "./locales/en.json"
import greek from "./locales/gr.json"

const localeName = localStorage.getItem("locale")
  ? localStorage.getItem("locale")
  : "en"

const resources = {
  en: {
    translation: english,
  },
  gr: {
    translation: greek,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localeName,
  // debug: true,
})

export default i18n

import "./App.css"
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom"
import Layout from "./components/Layout/Layout"
import Home from "./components/Home/Home"
import UploadFile from "./components/UploadFile/UploadFile"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider, elGR } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/el"
import Login from "./components/Login/Login"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import ForgotPassword from "./components/Login/ForgotPassword/ForgotPassword"
import PendingFiles from "./components/PendingFiles/PendingFiles"
import UploadedFiles from "./components/UploadedFiles/UploadedFiles"
import BiologicalData from "./components/BiologicalData/BiologicalData"
import Institution from "./components/Institution/Institution"
import PublicDashboard from "./components/PublicDashboard/PublicDashboard"
import PublicRoute from "./components/PrivateRoute/PublicRoute"

const theme = createTheme({
  palette: {
    primary: {
      main: "#76ABAE",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "white",
    },
    // secondary: "red",
  },
})

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/dashboard" element={<PublicRoute />}>
          <Route path="/dashboard" element={<PublicDashboard />} />
        </Route>
        <Route index path="/login" element={<Login />} />
        <Route path="reset-password" element={<ForgotPassword />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/institution" element={<Institution />} />
            <Route path="/bio-data" element={<BiologicalData />} />
            <Route path="/files/upload" element={<UploadFile />} />
            <Route path="/files/pending" element={<PendingFiles />} />
            <Route path="/files/all" element={<UploadedFiles />} />
          </Route>
        </Route>
      </>
    )
  )

  return (
    <div className="App">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        locale="en"
        adapterLocale="en"
        localeText={
          elGR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}

export default App

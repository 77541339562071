import React, { useEffect, useState } from "react"
import { MagnifyingGlass } from "react-loader-spinner"
import "./UploadFileSteps.css"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

export default function UploadFileStepTwo(props) {
  const [messageSuccess, setMessageSuccess] = useState("")
  const [fileWarning, setFileWarning] = useState(false)
  const [countdown, setCountdown] = useState(3)
  const [countdownCompleted, setCountdownCompleted] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    // Simulate an API call
    if (props.isAdmin) {
      fetch("https://dummyjson.com/products/1")
        .then(() => setFileWarning(false))
        .then(() => {
          if (!fileWarning) {
            setMessageSuccess(
              <Trans
                t={t}
                i18nKey="uploadFile.uploadFileStepTwo.successCheckingMessage"
              >
                Ο ελεγχος ολοκληρώθηκε με επιτυχία.
              </Trans>
            )
            props.setCheckFinished(true)
          }
        })
    } else {
      fetch("https://dummyjson.com/products/1")
        .then(() => setFileWarning(false))
        .then(() => {
          if (true) {
            setMessageSuccess(
              <Trans
                t={t}
                i18nKey="uploadFile.uploadFileStepTwo.successCheckingMessage"
              >
                Ο ελεγχος ολοκληρώθηκε με επιτυχία.
              </Trans>
            )
            props.setCheckFinished(true)
            const countdownIntervalId = setInterval(() => {
              setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                  clearInterval(countdownIntervalId)
                  setCountdownCompleted(true)
                  return 0
                } else {
                  return prevCountdown - 1
                }
              })
            }, 1000)

            return () => clearInterval(countdownIntervalId)
          } else {
            // TODO: show error message
          }
        })
    }
  }, [])

  useEffect(() => {
    if (countdownCompleted) {
      props.handleNext()
    }
  }, [countdownCompleted])

  return (
    <>
      {!fileWarning && messageSuccess === "" && (
        <div className="upload-file-step-two-loader-messages">
          <MagnifyingGlass
            visible={true}
            height="80"
            width="80"
            glassColor="#c0efff"
            color="#e15b64"
          />
          <h3>
            <Trans t={t} i18nKey="uploadFile.uploadFileStepTwo.checkingMessage">
              Γίνεται έλεγχος...
            </Trans>
          </h3>
        </div>
      )}

      {messageSuccess !== "" && (
        <div className="upload-file-step-two-loader-messages">
          <CheckCircleIcon sx={{ fontSize: "2.5rem" }} color="success" />
          <h3>{messageSuccess}</h3>
          {!props.isAdmin ? (
            <p>
              <Trans
                t={t}
                i18nKey="uploadFile.uploadFileStepTwo.successCheckingMessageInfoUser"
              >
                Ανεβάστε το αρχείο στο επόμενο βήμα... ({{ countdown }})
              </Trans>
            </p>
          ) : (
            <p>
              <Trans
                t={t}
                i18nKey="uploadFile.uploadFileStepTwo.successCheckingMessageInfoAdmin"
              >
                Το αρχείο είναι έτοιμο για αποστολή.
              </Trans>
            </p>
          )}
        </div>
      )}
    </>
  )
}

import React, { createContext, useState } from "react"
import SideBar from "../Headers/SideBar"
import { Outlet } from "react-router-dom"
import TopBar from "../Headers/TopBar"
import "../../App.css"

export const LayoutContext = createContext()

export default function Layout() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const [selectedInstitution, setSelectedInstitution] = useState(
    userInfo.institution_id
  )

  const contextValues = {
    selectedInstitution,
  }

  return (
    <LayoutContext.Provider value={contextValues}>
      <div>
        <TopBar />
        <div className="layout-main-wrapper">
          <SideBar setSelectedInstitution={setSelectedInstitution} />
          <div className="layout-main-outlet">
            <Outlet />
          </div>
        </div>
      </div>
    </LayoutContext.Provider>
  )
}

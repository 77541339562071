import React from "react"
import "./TopBar.css"
import LanguageIcon from "@mui/icons-material/Language"
import { changeLanguage } from "i18next"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { Link } from "react-router-dom"
import LockOpenIcon from "@mui/icons-material/LockOpen"

export default function TopBarPublic() {
  const { t } = useTranslation()

  function handleChangeLanguage() {
    const newLanguage = localStorage.getItem("locale") === "en" ? "gr" : "en"
    localStorage.setItem("locale", newLanguage)
    changeLanguage(newLanguage)
  }

  return (
    <div>
      <div className="topBar-wrapper">
        <div className="topBar-logo-container">
          <img
            width="35"
            height="35"
            src="https://img.icons8.com/fluency/96/virus.png"
            alt="virus"
          />
          <p className="topBar-logo">WhoNET Platform</p>
        </div>
        <div className="topBar-right-container">
          {/* <p className="topBar-right-contents">
              <AccountCircleRoundedIcon /> {userInfo?.username}
            </p> */}
          <p
            onClick={handleChangeLanguage}
            className="topBar-right-contents action"
          >
            <LanguageIcon />{" "}
            <Trans t={t} i18nKey="topBar.language">
              English
            </Trans>
          </p>
          <Link
            style={{ textDecoration: "none" }}
            to="/login"
            className="topBar-right-contents action"
          >
            <LockOpenIcon />{" "}
            <Trans t={t} i18nKey="topBar.login">
              Είσοδος
            </Trans>
          </Link>
        </div>
      </div>
    </div>
  )
}
